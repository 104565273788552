
import Balancer from 'react-wrap-balancer';
import cn from 'classnames';
import { Body, Heading } from '@components/typography';
import { Button, Flex } from '@components/common';
import { ContentfulModal } from '@components/contentful';

import styles from './InteractiveCard.module.scss';

export const InteractiveCardCollapsedContent = ({
	button1,
	button2,
	buttonClassName,
	description,
	extraHeaderClassName,
	subheading,
	shouldDisplayTitle,
	showTitle,
	title,
	withBalancer,
}) => {
	const contentDescription = withBalancer ? <Balancer>{description}</Balancer> : description;

	return (
		<Flex column justify='end' align='start' gap={3} className={cn(styles.header, extraHeaderClassName)}>
			{shouldDisplayTitle && (
				<Heading
					tag='h4'
					removeDefaultMargin
					style={{ opacity: showTitle ? '1' : '0' }}
					fixedSize
				>
					{title}
				</Heading>
			)}
			{subheading && (
				<Heading className={styles.subheading} tag='h5' removeDefaultMargin fixedSize >{subheading}</Heading>
			)}
			{!!description && (
				<Flex gap={3} justify='end' align='start' style={{ padding: '0.8rem 0rem 0rem', textAlign: 'end', width: '100%' }} className={cn(buttonClassName)}>
					<Body className={styles['description']}>{contentDescription}</Body>
				</Flex>
			)}
			{button1?.label && (
				<Flex gap={3} justify='start' align='start' style={{ padding: '0.8rem 0rem 0rem', marginTop: 'auto' }} className={cn(buttonClassName)}>
					{!!button1.modalContent ? (
						<ContentfulModal modal={button1.modalContent}>
							<Button
								extraClasses={cn(button1.className)}
								size='small'
								label={button1.label}
								color={button1.color || 'transparent-light'}
							/>
						</ContentfulModal>
					) : (
						<Button
							extraClasses={cn(button1.className)}
							size='small'
							label={button1.label}
							href={button1.link}
							color={button1.color || 'transparent-light'}
							fullWidth={button1.fullWidth || false}
							{...(button1.dataTags || {})}
						/>
					)}
					{button2 && (
						<Button
							extraClasses={cn(button2.className)}
							size='small'
							label={button2.label}
							href={button2.link}
							color={button2.color || 'transparent-light'}
						/>
					)}
				</Flex>
			)}
		</Flex>

	)
};
