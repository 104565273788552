import { useState } from 'react';
import { ContentfulComponentMap } from '@components/utils';
import { Heading } from '@components/typography';
import { Button, Flex, Modal } from '@components/common';
import styles from './ContentfulModal.module.scss';

const ContentfulModal = ({ modal, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	if (!modal) return null;

	const title = modal.title ?? modal.internalName;

	return (
		<Modal open={isOpen} onOpenChange={setIsOpen}>
			<Modal.Trigger asChild>
				{children}
			</Modal.Trigger>
			{/*TODO find way to remove hard-coded width*/}
			<Modal.Content removePadding closeButtonSticky style={{ maxWidth: '67.2rem' }}>
				<div className={styles['content']}>
					<div className={styles['header']}>
						<Heading tag='h5'>{title}</Heading>
					</div>
					<ContentfulComponentMap blocks={[modal.content]} />
				</div>
				{modal.displayCloseButton || (modal.buttonLink && modal.buttonLabel) ? (
					<Flex gap={3} className={styles['buttons']}>
						{modal.displayCloseButton && (
							<Button color='white' onClick={() => setIsOpen(false)}>
								Close
							</Button>
						)}
						{modal.buttonLink && modal.buttonLabel && (
							<Button fullWidth href={modal.buttonLink}>
								{modal.buttonLabel}
							</Button>
						)}
					</Flex>
				) : null}
			</Modal.Content>
		</Modal>
	);
};

export default ContentfulModal;
